<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Bevestiging van je emailadres" />
    <b-row>
      <b-col xs="12" md="8">
        <h1 class="pb-3">Bevestiging van je emailadres</h1>
        <p>De Home gaat aan de slag met je aanvraag</p>
        <ul>
          <li>
            Je ontvangt direct na deze registratie een e-mail, bevestig in deze
            e-mail je mailadres.
          </li>
          <li>
            Binnen de 2 werkdagen ontvang je een e-mail met de goed- of
            afkeuring van je account.
          </li>
        </ul>

        <p>
          Inloggen is pas mogelijk als je mailadres is bevestigd en je account
          is goedgekeurd.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs }
}
</script>

<style scoped lang="scss">
ul {
  list-style-type: inherit;
  margin-left: 20px;
}
.page-container {
  h4 {
    font-size: 35px;
  }
  p {
    font-size: 18px;
  }
}
</style>
